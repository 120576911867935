<template>

    <header class="block fixed top-0 left-0 right-0 z-[1000] shadow-md shadow-blue-700" data-sticky="true">
      <div class="flex pb-2 pt-4 md:pt-3 bg-[#57b5f5] border-b-1 border-white border-solid" >
          <div class="flex flex-col md:flex-row grid-cols-3 max-w-[1650px] mt-0 mb-0 mr-auto ml-auto w-full pl-4 pr-4 md:pl-9 md:pr-9 justify-center md:justify-between items-center">
              <div class="flex flex-row flex-nowrap relative w-full md:basis-2/12 justify-between">
                <div class="w-3/5 md:w-full p-2 md:p-0">
                  <a @click="router_push_to({
                          name: 'dash',
                              params: {
                                  activetableroute: 'mutabakat-islemleri'
                              },
                          }
                      )" class="cursor-pointer"><img src="/images/logo.png" class="md:pb-5 max-h-[70%] md:max-h-[100%]"/></a>
                </div>
                <div class="relative justify-end pr-2 pb-2 w-2/5 h-full block md:hidden text-2xl font-semibold text-white text-center">
                    {{ this.settings.company_name }}
                </div>
              </div>
              <div class="hidden md:block md:basis-4/12 justify-center md:justify-start w-full text-3xl md:text-5xl font-semibold text-center text-white">
                {{ this.settings.company_name }}
              </div>
              <div class="flex flex-row flex-nowrap relative md:basis-2/12 justify-center pt-0 w-full">
                <div class="flex flex-col text-2xl border border-solid border-white rounded-xl p-3 text-white w-full">
                    <div class="text-white font-semibold">
                        Güncel Kontör Sayısı:
                      </div>
                      <div class="text-white">
                        {{ this.settings.reconciliation_credit_info.toLocaleString("tr-TR", { maximumFractionDigits: 0 }) }} Adet
                      </div>
                  </div>
              </div>
              <div class="flex flex-row flex-nowrap relative md:basis-4/12 justify-end pt-2 md:pt-0 w-full">
                <div class="w-3/12 border border-solid border-white rounded-xl p-1.5 mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer bg-blue-500 hover:bg-blue-800"
                        @click="router_push_to({
                          name: 'dash',
                              params: {
                                  activetableroute: 'mutabakat-islemleri'
                              },
                          }
                      )"
                      > 
                      <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                          <div>
                            <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
                            </svg>
                              <!-- END CASH END -->
                          </div>
                          <div class="text-2xl font-semibold text-center text-white">
                              Mutabakat<br />Listesi
                          </div>
                      </div>
                </div>
                <div class="w-3/12 border border-solid border-white rounded-xl p-1.5 mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer bg-blue-500 hover:bg-blue-800"
                      @click="router_push_to({
                          name:'bakiye-yukle',
                          params: {
                              activetableroute: 'orders'
                          }
                      })"
                      > 
                      <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                          <div>
                              <svg class="h-10 w-10 md:h-14 md:w-14 stroke-white hover:fill-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                              </svg>
                              <!-- END CASH END -->
                          </div>
                          <div class="text-2xl font-semibold text-center text-white">
                              Kontör<br />Yükle
                          </div>
                      </div>
                </div>
                <div class="w-3/12 border border-solid border-white rounded-xl p-1.5 mt-0 mb-0 ml-4 md:ml-2 mr-3 md:mr-1 relative inline-block items-center cursor-pointer bg-blue-500 hover:bg-blue-800"
                      @click="router_push_to({
                          name: 'dash',
                              params: {
                                  activetableroute: 'islem-listesi'
                              },
                          }
                      )" >

                      <div class="flex flex-col pr-2 pl-2 items-center min-w-[60px]">
                          <div>
                            <svg class="h-10 w-10 md:h-16 md:w-16 stroke-white hover:fill-green-800 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                            </svg>
                          </div>
                          <div class="text-2xl font-semibold text-center text-white">
                              İşlem<br />Listesi
                          </div>
                      </div>
                </div>
                <div class="w-3/12 flex md:flex-col flex-row flex-nowrap">
                    <div class="mt-0 mb-0 ml-2 md:ml-5 mr-2 md:mr-5 relative inline-block text-white w-1/5" v-tooltip.bottom="'İşlemler'" 
                      @click="toggle" >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12 md:h-16 md:w-16 stroke-white cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </div>
                    <div class="mt-0 mb-0 ml-4 md:ml-5 mr-4 md:mr-5 relative inline-block text-white w-1/5" v-tooltip.bottom="'Çıkış Yap'" 
                      @click="router_push_to({
                          name: 'login',
                          params: {},
                          }
                        )" >
                      <svg class="h-12 w-12 md:h-16 md:w-16 stroke-white cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                    </div>
                </div>
              </div>
          </div>
      </div>
    </header>
    

</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Header',
    data(){
        return {

            
        }
    },

    computed: {

        ...mapGetters(
            {
                settings        : 'get_settings',
                account_info    : 'get_user_account'
            }
        )
    },

    methods: {

        async router_push_to( locationNparams ){
            this.$router.push({
                name:locationNparams.name,
                params: locationNparams.params
            })
            if(locationNparams.name == 'login')this.$store.dispatch('reset_state',{});

            if (this.account_info.lastlogindate != undefined){ // 10.03.2023 - Gunluk giris tarih kontrolu
              const controlToday    = new Date();
              const controlDate     = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
              const UserDate        = new Date(this.account_info.lastlogindate);
              const controlUserDate = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
              if (Number(controlDate) != Number(controlUserDate)){
                this.$router.push(
                    {name: 'login'}
                )
              } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }
             }
            } else { // 09.03.2023 - Sistem guncellenme kontrolu 
                const system_status_info =  await this.$store.dispatch('system_control')
                .then( (res) => {
                    return res
                })
                .catch(
                    (err)=>{
                        console.log(err)
                    }
                )

                if (!system_status_info){
                    this.$router.push(
                        {name: 'login'}
                    )
                    // console.log(system_status_info)
                    // console.log("this.account_info.system_status:" + this.account_info.system_status)

                } else { // 17.11.2022 guncel tags bilgileri aliniyor
                    const user_acc =  await this.$store.dispatch('testing_login_new_info', { acc_email:this.account_info.username, acc_password:this.account_info.pass})
                    .then( (res) => {
                        //console.log("Kullanıcı bulundu !")
                        return res
                    })
                    .catch(
                        (err)=>{
                            console.log(err)
                        }
                    )
                }

            }
        },
        
    }
}
</script>
