import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import store from '../store/index'



const routes = [

  {
    path: '/',
    name:'login',
    component: LoginView
  },
  {
    path: '/giris',
    name:'giris',
    component: () => import('../views/DashView.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_userloginsuccess // 25.11.2022  -   Satis yoneticisi giris tarih kontrolu
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/giris_yap'};
      }
    }
  },
  {
    path: '/emutabakat/:recId',
    name:'emutabakat',
    component: () => import('../views/ReconciliationView.vue'), // 28.12.2022 - e-Mutabakat gelistirmesi
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_userloginsuccess // 25.11.2022  -   Satis yoneticisi giris tarih kontrolu
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/giris_yap'};
      }
    }
  },
  {
    path: '/bakiye-yukle',
    name: 'bakiye-yukle',
    component: () => import('../views/MakeAPayment.vue'),
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_userloginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path:'/dash/:activetableroute',
    name:'dash',
    component: () => import( '../views/DashView.vue'),
    props: true,            /////////-------------- PARAMSLER PROP OLUYOR BU ROUTETA 
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_userloginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path: '/kurumsal/:page_link',
    name: 'kurumsal',
    component: () => import( '../views/PageView.vue'),
    props: true,
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_userloginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/search',
    beforeEnter: (to, from) => 
    {
      const loggedin = store.getters.get_userloginsuccess
      if(loggedin == true)
      {
        return true;
      }
      else 
      {
        return { path: '/'};
      }
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
