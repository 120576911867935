
import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";


// todo add  printdir to every func 
// todo turn axios into fetch get rid of axios 


function printdir(currentloc, dataname, data) {
    //console.log('current loc => ', currentloc, '  dataname -> ', dataname)
    //console.dir(data)
}

const using_urls = {
            exchange_control            :   '/kur_kontrol', // 26.11.2022 - Gunluk kur kontrolu yapiliyor
            search                      :   '/ara',
            islem_table                 :   '/islem_table',

            get_settings_pages          :   '/settings_pages',

            system_control              :   '/sistem-kontrol', // 09.03.2023 - Sistem guncellenme kontrolu 

            user_login                  :   '/giris-yap',

            cash_make_payment           :   '/odeme_tmmla_kk',
            cash_make_payment3D         :   '/odeme_tmmla_kk3d',

            user_make_payment           :   '/odeme_banka_bilgisi',
            easy_payment_pay_info       :   '/kredi_karti_odeme_bilgisi',

            user_actions                :   '/satis_kullanici_islemleri', //28.03.2023 - kullanici islemleri

            ayarlar_kullanici_listesi   :   '/ayarlar_kullanici_listesi', //15.05.2023 - tum urun listesi
            ayarlar_islem_listesi       :   '/ayarlar_islem_listesi', //07.04.2023 - tum kredi karti islem listesi
            ayarlar_mutabakat_listesi   :   '/ayarlar_mutabakat_listesi', //07.12.2023 - tum mutabakat listesi
            ayarlar_mutabakat_liste_detay:  '/ayarlar_mutabakat_liste_detay', //07.12.2023 - mutabakat liste detaylari
            ayarlar_mutabakat_islemleri :   '/ayarlar_mutabakat_islemleri', //11.12.2023 - mutabakat islemleri

}

export default createStore({
    //                 GETTERS
   getters: {
       
        search_result(state){
            const page = 'search'
            if( page in state.loadedpages && 'data' in state.loadedpages[page] ){
                return state.loadedpages[page]['data']
            }
        },
        get_user_account(state){
            return state.user_account
        },
        get_payment_installment(state)
        {
            return state['payment_installment']
        },
        get_bank_list(state){
            return state['bank_pos_info']
        },

        // B 25.11.2022  -   Satis yoneticisi giris tarih kontrolu
        get_userloginsuccess(state){

            var lastloginstatus = false;
            if (state['satisloginsuccess'] == true){
                const controlToday  = new Date();
                const controlDate   = String(controlToday.getFullYear()) + String(controlToday.getMonth()+1).padStart(2,"0") + String(controlToday.getDate()).padStart(2,"0")
                if (state['user_account'] != undefined){
                    if (state['user_account'].lastlogindate != undefined){
                        // console.log("lastlogindate-user_account: " + state['user_account'].lastlogindate)
                        const UserDate      = new Date(state['user_account'].lastlogindate);
                        const controlUserDate   = String(UserDate.getFullYear()) + String(UserDate.getMonth()+1).padStart(2,"0") + String(UserDate.getDate()).padStart(2,"0")
                        if (Number(controlDate) == Number(controlUserDate)){
                            lastloginstatus = true;
                        }
                    }
                }
            }

            return lastloginstatus
        },
        // E 25.11.2022  -   Satis yoneticisi giris tarih kontrolu

        get_settings(state)
        {
            return state['settings']
        },
        get_exchanges(state)
        {   

            const exchange_rates = state.settings.used_exchange_rates 
            const user_rates     = state.settings.exchange_USER
            const tcmb_rates     = state.settings.exchange_TCMB

            var current_date = getDate_yyyymmdd()

            if (tcmb_rates == undefined){ //03.12.2022 - tcmb_rates degeri yok ise islem yapiliyor
                return {}
            }

            const get_last_tcmb_rates = tcmb_rates.slice(-1); //en son alinan tcmb kur bilgisi
            const tcmb_current_last_date = get_last_tcmb_rates[0].tarih;

            if (current_date != tcmb_current_last_date) { //gunun tarihi ile son tcmb kur tarihi farkli ise tcmb kur tarihi alinacak - ozellikle resmi tatillerden sonra tatil tarihli tcmb kuru olmuyor
                current_date = tcmb_current_last_date;
            }

            const get_used_rate = exchange_rates.map(
                i => {
                    if( i.senkron )
                    {
                        var retthis = tcmb_rates.filter(
                            j => {
                                return (j.kurismi == i.kurismi) && (j.tarih == current_date)
                            }
                        ).pop()
                        if(retthis && i.symbol){
                        retthis['symbol'] = i.symbol
                        return retthis}
                    } else 
                    {
                        var retthis = user_rates.filter(
                            j => {
                                return j.kurismi == i.kurismi 
                            }
                        ).pop()
                        if(retthis && i.symbol){
                        retthis['symbol'] = i.symbol
                        return retthis}
                    }
                }
            )

            return get_used_rate

        },
        get_exchanges_tcmb(state)
        {   

            const exchange_rates = state.settings.used_exchange_rates 
            const tcmb_rates     = state.settings.exchange_TCMB

            var current_date = getDate_yyyymmdd()

            if (tcmb_rates == undefined){ //03.12.2022 - tcmb_rates degeri yok ise islem yapiliyor
                return {}
            }

            const get_last_tcmb_rates = tcmb_rates.slice(-1); //en son alinan tcmb kur bilgisi
            const tcmb_current_last_date = get_last_tcmb_rates[0].tarih;

            if (current_date != tcmb_current_last_date) { //gunun tarihi ile son tcmb kur tarihi farkli ise tcmb kur tarihi alinacak - ozellikle resmi tatillerden sonra tatil tarihli tcmb kuru olmuyor
                current_date = tcmb_current_last_date;
            }

            const get_used_rate = exchange_rates.map(
                i => {

                        var retthis = tcmb_rates.filter(
                            j => {
                                return (j.kurismi == i.kurismi) && (j.tarih == current_date)
                            }
                        ).pop()
                        if(retthis && i.symbol){
                        retthis['symbol'] = i.symbol
                        return retthis}
                }
            )

            return get_used_rate

        },
        get_dash_active_table(state)
        {
            return state.dash_active_table
        },

        get_store_size(state){
            const state_size = JSON.stringify(state).length * 2
            console.log('current state size '  + state_size/1024 + ' kb')

            const all_state_keys = Object.keys(state)

            all_state_keys.map(
                i=> {
                    const l_state_size = JSON.stringify(state[i]).length * 2
                    console.log(i + ' ' + l_state_size/1024 + ' kb')
                    const one_more_key  = Object.keys(state[i])
                    one_more_key.map(
                        j => {
                            const ll_state_size = JSON.stringify(state[i][j]).length * 2
                            console.log("   - " + j + " " + ll_state_size/1024 + ' kb')
                        }
                    )
                }
            )

            return state_size
        }
        
    },
    //                  MUTATIONS 
    mutations: {

        reset_state(state,payload)
        {
            // store.replaceState({})
            // this.reset()
            // store.reset()
            // state.reset()

            const keys_array = Object.keys(state)

            keys_array.map(
                i=> {
                    state[i]={}
                }
            )
            Object.assign(state, {})

            // console.dir(state)
        },
        //============= LOGIN INITILIZATION =============
        //
        //
        //
        //===============================================
        toolBar(state, val){
            document.title = val.pageTitle
            state.toolBar = val
        },

        loadmorepagesmut( state, payload ) {

            var doesloaded_pages = 'loadedpages' in state
            if(!doesloaded_pages) state['loadedpages'] = {}
            
            var doesloadedexist = payload.page in state.loadedpages
            if( !doesloadedexist ) 
            {
                state.loadedpages[payload.page] = {}
            };


            if (payload.isonlyload) 
            {
                state.loadedpages[payload.page]['data'] = state.loadedpages[payload.page]['data'].concat( payload.data )
            }
            else {
                state.loadedpages[payload.page]['data'] = []
                Object.assign(state.loadedpages[payload.page]['data'], payload.data)
            }
            state.loadedpages[payload.page]['reqsent']             =  payload.reqsent
            state.loadedpages[payload.page]['isopen']              =  payload.isopen
            state.loadedpages[payload.page]['startofresultreturn'] =  state.loadedpages[payload.page]['data'].length + 1
        },
        //============== GETTING PAST ORDERS ===========
        //
        //
        //
        //
        //=============================================
        save_past_orders(state, payload) {
            state.past_orders = payload
        },
        
        erase_bank_pos_list_from_settings(state)
        {
            state.settings.bank_pos_info = {}
            Object.assign(state.settings.bank_pos_info,{})
        },
        save_payment_installment(state, payload){
            state['payment_installment'] = payload
        },
        save_bank_pos_info(state, payload){
            state['bank_pos_info'] = payload
        },
        save_domain_info( state, payload)
        {
            state['domain'] = payload
        },
        save_loginsuccess( state, payload)
        {
            state['loginsuccess'] = payload
        },
        // B 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        save_satisloginsuccess( state, payload)
        {
            state['satisloginsuccess'] = payload
        },
        // E 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        save_settings( state, payload)
        {
            state['settings'] = payload
        },
        save_salesperson( state, payload)
        {
            state['salesperson'] = payload
        },
        save_dash_active_table(state, payload)
        {
            state['dash_active_table'] = payload
        },
        save_user_account(state,payload)
        {
            state['user_account'] = payload
        },
        save_selected_company(state, payload)
        {
            state['selected_company'] = payload
        },
        save_reset_selected_company(state)
        {
            state['satis_cart'] = {}
            state['satis_cust_cart'] = {}
            state['selected_company'] = {}
            state['search_customer'] = {}
        },

        save_settings_pages(state, payload)
        {
            state['settings']['pages'] = payload.data
        },

        save_settings_exchange(state, payload)
        {
            state['settings']['used_exchange_rates']    = payload.used_exchange_rates
            state['settings']['exchange_USER']          = payload.exchange_USER
        }

        
    },
    //                  ACTIONS 
    actions: 
    {

        async system_control({ state }){ // B 09.03.2023 - Sistem guncellenme kontrolu 

            const reqsend = {
                domain  : state.domain,
            }
            var system_status_info = false
            var system_status = ""

            if (state['user_account'].system_status != undefined){
                await fetch( using_urls.system_control, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {
                    system_status = data.system_status
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

                if (state['user_account'].system_status == system_status){
                    system_status_info = true
                }
            }

            return system_status_info
        }, // E 09.03.2023 - Sistem guncellenme kontrolu 


        async exchange_control( {commit, dispatch}, payload) { // B 26.11.2022 - Gunluk kur kontrolu yapiliyor

            await fetch( using_urls.exchange_control, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                })
                .then(response => response.json())
                .then(data => {

                    //console.log("today_exchange_length:" + data.today_exchange_length)

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }, // E 26.11.2022 - Gunluk kur kontrolu yapiliyor

        async get_appusers({ state }, payload) {

            var retthis = {}

            const reqsend = {
                domain : state.domain
            }
            await fetch( using_urls.ayarlar_kullanici_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_users
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_user_transaction_list({ state }, payload){

            var retthis = {}

            const reqsend = {
                satis_cust_no   : payload.satis_cust_no,
                satis_date_start: payload.satis_date_start,
                satis_date_end  : payload.satis_date_end,
                domain          : state.domain
            }
            await fetch( using_urls.ayarlar_islem_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_trans
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis

        },

        async user_login( {commit, dispatch}, payload) {
            await fetch( using_urls.user_login , {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    })
                    .then(response => response.json())
                    .then(data => {
                        commit('save_user_account', data.account_info)
                        dispatch('commit_satisloginsuccess', data.login_sucess) // 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
                        dispatch('commit_settings_data', data.site_settings)
                        dispatch('commit_domain_info', data.account_info.domain) //12.04.2023 - domain bilgisi alinamadigindan eklendi
                        dispatch('commit_bank_pos_info' , data.site_settings.bank_pos_list)
                        // commit('save_dash_active_table', {
                        //     active_table : 'mutabakat_islemleri'
                        // })
                        dispatch('act_get_pages',{});
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
        },

        async user_actions({state, commit}, payload){

            const reqsend = {
                user_firstname    : payload.user_firstname,
                user_surname      : payload.user_surname,
                user_email        : payload.user_email,
                user_phone        : payload.user_phone,
                user_oldusername  : payload.user_oldusername,
                user_username     : payload.user_username,
                user_pass         : payload.user_pass,
                user_type         : payload.user_type,
                user_permissions  : payload.user_permissions,
                user_status       : payload.user_status,
                user_platform_b2b : payload.platform_b2b,
                user_platform_b2c : payload.platform_b2c,
                user_data         : payload.user_data,
                action_type       : payload.action_type,
                domain            : state.domain,
            }

            // console.log("reqsend:" + JSON.stringify(reqsend))

            var return_data = ""

            await fetch(using_urls.user_actions, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // commit('save_account_cart_item_detail', data.itemsdetails)

                return_data = data

            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async satis_settings_actions({state, commit}, payload){

            const reqsend = {
                exchange_select : payload.exchange_select,
                domain          : state.domain,
            }

            var return_data = ""

            await fetch(using_urls.satis_setting_actions, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                commit('save_settings_exchange', data)
                // return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async transaction_payment_info({state, commit, dispatch }, payload){ //gelen odeme hareketi bilgileri cekiliyor -------------------------------------

            const order_no  = payload.order_no 
            const domain    = payload.domain

            const reqsend = {
                order_no  : order_no,
                domain    : domain,
            }

            var data_info = null;

            await fetch( using_urls.easy_payment_pay_info, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        // console.log("data: " + JSON.stringify(data))
                        data_info = data;
                        return data_info
                    })
                    .catch( error => console.error('Error:', error));

            // console.log("data_info: " + JSON.stringify(data_info))
    
            return data_info
        },

        async payment_get_bank_info({ dispatch }, payload){ //credit card no onkeypress ----------------------------------------------

            const user_bank_bin   = payload.user_bank_bin
            // console.log(user_bank_bin)
            // return

            const reqsend = {
                user_bank_bin   : user_bank_bin,
            }

            var payment_data = {}

            await fetch( using_urls.user_make_payment, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
                })
                .then(response => response.json())
                .then(data => {

                    payment_data = data;

                    //console.log(`Data:${JSON.stringify(payment_data)}`)
                    
                })
                .catch( error => console.error('Error:', error));

                dispatch('commit_payment_installment', payment_data)

                return
        },
        
        async cash_make_payment({state, commit, dispatch }, payload){

            const accountId         = state.user_account.company_id
            const cust_no           = state.user_account.company_no
            const cust_name         = state.user_account.company_name
            const domain            = state.domain
            const card_number       = payload.card_number 
            const card_date_month   = payload.card_date_month
            const card_date_year    = payload.card_date_year
            const card_cvv          = payload.card_cvv
            const card_ownername    = payload.card_ownername
            const card_gsm          = payload.card_gsm
            const installment       = payload.installment
            const mainpos_code      = payload.mainpos_code
            const payment_method    = payload.payment_method
            const payment_amount    = payload.payment_amount
            const payment3D         = payload.payment3D
            const payment_page      = payload.payment_page || "SystemPay" // 21.11.2022 - Kolay-ode 3D gelistirmesi
            const credit_quantity   = payload.credit_quantity


            const reqsend = {
                accountId         : accountId,
                cust_no           : cust_no,
                cust_name         : cust_name,
                domain            : domain,
                card_number       : card_number,
                card_date_month   : card_date_month,
                card_date_year    : card_date_year,
                card_cvv          : card_cvv,
                card_ownername    : card_ownername,
                card_gsm          : card_gsm,
                installment       : installment,
                mainpos_code      : mainpos_code,
                payment_method    : payment_method,
                payment_amount    : payment_amount,
                payment3D         : payment3D,
                payment_page      : payment_page, // 21.11.2022 - Kolay-ode 3D gelistirmesi
                credit_quantity   : credit_quantity,
            }

            var payStatus = {};

            const reconciliation_credit_info = state['settings']['reconciliation_credit_info'];           

            if ((payment3D == true) || (payment3D == "true")){


                await fetch( using_urls.cash_make_payment3D, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
    
                            payStatus = data

                            //Odeme Basarili ise kredi bilgisi guncelleniyor
                            state['settings']['reconciliation_credit_info'] = (payStatus == true) ? (Number(reconciliation_credit_info) + Number(credit_quantity)) : Number(reconciliation_credit_info)
                        
                    })
                    .catch( error => console.error('Error:', error));

                    return payStatus

            } else {

                await fetch( using_urls.cash_make_payment, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
    
                        // console.log("data: " + JSON.stringify(data))
                        
                        if ((payment_page == "CartPay") && (data.payStatus == true)){ //odeme basarili ise isleme devam edecek
                            dispatch('complete_order', data)
                        }
                        //Odeme Basarili ise kredi bilgisi guncelleniyor
                        state['settings']['reconciliation_credit_info'] = (data.payStatus == true) ? (Number(reconciliation_credit_info) + Number(credit_quantity)) : Number(reconciliation_credit_info)

                        payStatus = data;
                        
                    })
                    .catch( error => console.error('Error:', error));

                    //console.log(payStatus)
    
                    return payStatus
            }
        },

        async get_satis_reconciliations({ state }, payload) {

            var retthis = {}

            const reqsend = {
                search_status   : payload.search_status,
                domain          : state.domain
            }

            await fetch( using_urls.ayarlar_mutabakat_listesi, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_reconciliations
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async get_satis_reconciliation_detail({ state }, payload) {

            var retthis = {}

            const reqsend = {
                search_status_detail    : payload.search_status_detail,
                reconciliation_id       : payload.reconciliation_id,
                domain                  : state.domain
            }

            await fetch( using_urls.ayarlar_mutabakat_liste_detay, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(reqsend),
                    })
                    .then(response => response.json())
                    .then(data => {
                        retthis = data.ret_reconciliations
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

            return retthis
        },

        async satis_reconciliation_actions({state}, payload){

            const reqsend = {
                reconciliation_id           : payload.reconciliation_id,
                reconciliation_description  : payload.reconciliation_description,
                reconciliation_startingDate : payload.reconciliation_startingDate,
                reconciliation_endingDate   : payload.reconciliation_endingDate,
                reconciliation_dueDate      : payload.reconciliation_dueDate,
                reconciliation_sti_auditor  : payload.reconciliation_sti_auditor,
                reconciliation_auditor_name : payload.reconciliation_auditor_name,
                reconciliation_auditor_email: payload.reconciliation_auditor_email,
                action_type                 : payload.action_type,
                upload_data                 : payload.upload_data,
                upload_type                 : payload.upload_type,
                domain                      : state.domain,
            }

            var return_data = {}

            await fetch(using_urls.ayarlar_mutabakat_islemleri, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        
        async cust_reconciliation_actions({state}, payload){

            const reqsend = {
                reconciliation_id   : payload.reconciliation_id,
                customerAmount      : payload.customerAmount,
                customerDebtCredit  : payload.customerDebtCredit,
                action_type         : payload.action_type,
                approverNameSurname : payload.approverNameSurname,
                approverNotes       : payload.approverNotes,
                domain              : state.domain,
                accountno           : payload.cust_no,
                accessType          : payload.accessType,
            }

            var return_data = {}

            await fetch(using_urls.cust_reconciliation, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },

        async cust_reconciliation_ledger_list({state}, payload){

            const reqsend = {
                accountno           : payload.cust_no,
                endingDate          : payload.endingDate,
                domain              : state.domain,
            }

            var return_data = {}

            await fetch(using_urls.cust_reconciliation_ledger_list, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                return_data = data.cust_ledger_list
            })
            .catch( error => console.error('Error:', error));

            return return_data
        },


        get_kurr({state, getters}, curr_code_in){
            if( curr_code_in.includes('EUR-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('EURO')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexsat)
            }
            if( curr_code_in.includes('USD-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('US DOLLAR')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexsat)
            }
            if( curr_code_in.includes('GBP-MS')){ // 28.11.2022  -   MA-MS kur duzenlemesi yapildi
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('POUND')
                    }
                ).pop()

                return parseFloat(curr_obj.forexsat)
            }
            // B 28.11.2022  -   MA-MS kur duzenlemesi yapildi
            if( curr_code_in.includes('EUR-MA')){
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('EURO')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexal)
            }
            if( curr_code_in.includes('USD-MA')){
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('US DOLLAR')
                    }
                ).pop()

                return  parseFloat(curr_obj.forexal)
            }
            if( curr_code_in.includes('GBP-MA')){
                const curr_obj = getters.get_exchanges.filter(
                    i=>{
                        return i.kurismi.includes('POUND')
                    }
                ).pop()

                return parseFloat(curr_obj.forexal)
            }
            // E 28.11.2022  -   MA-MS kur duzenlemesi yapildi

            return 1
        },


        async reset_state_1({state, commit}, payload)
        {
            commit('reset_state',{})
        },
        async commit_payment_installment ( {commit}, payload)
        {
            commit('save_payment_installment', payload)
        },
        async commit_bank_pos_info( {commit}, payload)
        {
            commit('save_bank_pos_info',payload)
        },
        async commit_domain_info( {commit}, payload)
        {
            commit('save_domain_info', payload)
        },
        async commit_loginsuccess( { commit}, payload)
        {
            commit('save_loginsuccess',payload)
        },
        // B 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        async commit_satisloginsuccess( { commit}, payload)
        {
            commit('save_satisloginsuccess',payload)
        },
        // E 25.11.2022  -   Satis yoneticisi giris tarih kontrolu  
        async commit_settings_data( {commit}, payload)
        {
            commit('save_settings' , payload)
        },
        async commit_salesperson_data( {commit}, payload)
        {
            commit('save_salesperson' , payload)
        },
        async set_dash_active_table( {commit}, payload)
        {
            commit('save_dash_active_table', payload.active_table)
        },

        async act_get_pages({state,commit},payload)
        {
            const reqsend = {}


            await fetch( using_urls.get_settings_pages, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqsend),
            })
            .then(response => response.json())
            .then(data => {
                // console.dir(data)
                commit('save_settings_pages', data)
            })
            .catch( error => console.error('Error:', error));
        }
    },

    plugins: [createPersistedState()],
})

function getDate_yyyymmdd() {

    const date = new Date();

    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');

    return `${yyyy}${mm}${dd}`
}