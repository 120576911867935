<template>
  <router-view/>
  <modals-container></modals-container>

</template>

<script>
import { mapGetters } from 'vuex'

    export default {
        name: 'app',
        created () {
            document.title = this.settings.company_title || 'e-Mutabakat Sistemimize Hoş Geldiniz';
        },
        methods: {
            return_mili_to_next()
            {
                const date_time_now = new Date()
                const date_time_midnight = new Date()
                date_time_midnight.setHours(24,0,0,0)
                return date_time_midnight-date_time_now
            },

            logout_at_midnight()
            {
             setTimeout(() => {
                this.$router.push(
                   { name:'login',
                    params: ''}
                )
                this.$store.dispatch('reset_state',{})
             }, this.return_mili_to_next());   
            }
        },
        computed: {
            ...mapGetters({
                settings : 'get_settings'
            }),
        }
    }
</script>

<style>

</style>

