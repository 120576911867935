<template>
    <footer class="pt-16 bg-gray-100 w-full">
    <div class="max-w-[1650px] m-0 mr-auto ml-auto pl-2 pr-2 md:pl-14 md:pr-14">

        <div class="flex flex-col w-full">

            <div class="w-full border-t border-solid border-gray-300">

                <div class="flex flex-col md:flex-row flex-nowrap grid-cols-2 w-full justify-between pt-6 pb-2 border-t border-solid border-white">

                    <div class="relative pb-6 mb-4">
                        <img src="/images/ortaksoft.png" title="OrtakSoft B2B Çözümleri" class="w-[130px] cursor-pointer" onclick="window.open('https://www.ortaksoft.com','_blank')">
                    </div>
                    <div class="pl-5 mt-2 mb-4">
                        <a href="https://www.ortaksoft.com" alt="OrtakSoft B2B Çözümleri" target="_blank">OrtakSoft e-Mutabakat Çözümleri - © 2023 Tüm Hakları Saklıdır</a>
                    </div>
                    <div class="relative pl-5 mt-2 md:mt-0 pb-6 mb-4">
                        <p><span class="text-black">Ödeme Seçeneklerimiz :</span> <img src="/img/cc-logo.png" class="pr-16"> </p>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <!-- Back to top button -->
        <div 
        id="ScrollTopButton" 
        class="sticky w-full flex justify-end bottom-0 pb-5 pr-5 lg:pr-3 transition">
            <div class="w-24 h-24 u-center bg-gray-300 hover:bg-gray-400 mt-2.5 cursor-pointer transition-colors ease-out duration-200 rounded border-2 focus:outline-none focus:ring px-3 py-1.5 bg-alpha-60 hover:bg-alpha-80 text-white border-transparent" title="Yukarı Çık">
                <button @click="scrollToTop" class="" role="button" aria-label="Yukarı Kaydır">
                    <svg class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                    </svg>
                </button>
            </div>
        </div>
    
</footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'// B 17.11.2022 guncel tags bilgileri aliniyor

export default {
    data () {
        return {
            bellow_y : false
            }
    },
    computed: {
        
        bellow_req()
        {
            //console.log(window.scrollY)
            return window.scrollY < 400
        }
    },

    methods: {
        scrollToTop() {
                window.scrollTo({ top: 0, behavior: "smooth" });
        },

        check_hight() 
        {
            const const_h = 400
            window.onscroll = () => 
            {
                this.bellow_y =  window.scrollY < const_h
            }
        },

    },
    mounted() {
        this.check_hight()
    },
    
}
</script>