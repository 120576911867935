export default {
    methods: 
    {
      format_tl(numin,curr_code_in) {

        const curr_code = curr_code_in || " "
    
        const usd_format = Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 3,
                });
        const try_format = new Intl.NumberFormat("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                  minimumFractionDigits: 3,
                });
    
        const eur_format = Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 3,
                });
    
        const gbp_format =  Intl.NumberFormat('en-GB', {// BCP 47 language tag 
                style: 'currency', // we want a currency
                currency: 'GBP', // ISO 4217 currency code
                minimumFractionDigits: 3,
            });
    
        var retthis = try_format.format(numin)
        if(curr_code.includes("USD")) retthis = usd_format.format(numin);
        if(curr_code.includes("EUR")) retthis = eur_format.format(numin);
        if(curr_code.includes("GBP")) retthis = gbp_format.format(numin);
        return retthis
      },

      format_date_to_ms(date_value_in){

        var date_value = date_value_in.toString();
  
        if (date_value.indexOf(".") > 0){
  
          var parts = date_value.trim().split(' ');
          var date = parts[0].split('.');
          var time = (parts[1] ? parts[1] : '00:00:00').split(':');
  
          var d = new Date(date[2],String(date[1]).padStart(2,"0"),String(date[0]).padStart(2,"0"),String(time[0]).padStart(2,"0"),String(time[1]).padStart(2,"0"),String(time[2]).padStart(2,"0"));
  
          return Math.floor(d.getTime() / 1000)
  
        } else {
          return date_value
        }
      },

    },
  }